import "../stylesheets/top.scss";

// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

import "../stylesheets/top.scss";

// init Swiper:
const swiper1 = new Swiper(".swiper1", {
  loop: true,
  speed: 1500,
  effect: "fade",
  autoplay: {
    delay: 8000,
    disableOnInteraction: false,
  },
  allowTouchMove: false,
});

const sliderSpeed = 3000;

// init Swiper:
const swiper2 = new Swiper(".swiper2", {
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  loop: true,
  spaceBetween: 20,
  slidesPerView: 2.5,
  // allowTouchMove: false,
  speed: sliderSpeed,
  centeredSlides: true,

  breakpoints: {
    1200: {
      spaceBetween: 40,
      slidesPerView: 6.5,
    },

    1000: {
      spaceBetween: 35,
      slidesPerView: 5.5,
    },

    800: {
      spaceBetween: 30,
      slidesPerView: 4.5,
    },

    600: {
      spaceBetween: 25,
      slidesPerView: 3.5,
    },
  },
});

// モーダル
const swiper2ModalBg = document.querySelector(".p-swiper2-modal__bg");
const swiper2ModalCloseElms = document.querySelectorAll(
  ".p-swiper2-modal__close"
);
const swiper2ModalContents = document.querySelectorAll(
  ".p-swiper2-modal__content"
);

let swiper2SlideElms = document.querySelectorAll(
  ".p-swiper2 > .swiper-wrapper > .swiper-slide"
);

swiper2SlideElms.forEach((swiper2SlideElm, index) => {
  swiper2SlideElm.addEventListener("click", () => {
    swiper2.autoplay.stop();

    const AriaLabel = swiper2SlideElm.getAttribute("aria-label");
    console.log(AriaLabel);
    // スラッシュで区切られた文字列を配列に変換
    const AriaLabelArray = AriaLabel.split("/");
    // 配列の最初の要素を取得
    const AriaLabelFirst = AriaLabelArray[0];
    // 数値に変換
    const convertIndex = (parseInt(AriaLabelFirst) - 1) % 6;

    swiper2ModalBg.classList.add("is-active");
    const swiper2ModalContent = swiper2ModalContents[convertIndex];
    const swiper2ModalCloseElm = swiper2ModalCloseElms[convertIndex];

    swiper2ModalContent.classList.add("is-active");

    swiper2ModalCloseElm.addEventListener("click", () => {
      closeModal(swiper2ModalBg, swiper2ModalContent);
      swiper2.autoplay.start();
    });

    swiper2ModalBg.addEventListener("click", () => {
      closeModal(swiper2ModalBg, swiper2ModalContent);
      swiper2.autoplay.start();
    });
  });
});

function closeModal(swiper2ModalBg, swiper2ModalContent) {
  swiper2ModalBg.classList.add("fade-out");
  swiper2ModalContent.classList.add("fade-out");

  setTimeout(() => {
    swiper2ModalBg.classList.remove("fade-out");
    swiper2ModalContent.classList.remove("fade-out");
    swiper2ModalBg.classList.remove("is-active");
    swiper2ModalContent.classList.remove("is-active");
  }, 450);

  const iframeElm = swiper2ModalContent.querySelector("iframe");
  console.log(iframeElm);

  iframeElm.contentWindow.postMessage(
    '{"event":"command","func":"stopVideo","args":""}',
    "*"
  );
}

function stopAutoPlay() {
  let getTranslate = swiper2.getTranslate();
  swiper2.setTranslate(getTranslate);
  swiper2.setTransition(0);
}

function startAutoPlay() {
  let getTranslate = swiper2.getTranslate();

  let getSlideWidthMgLeft = document.querySelector(".swiper-slide-active").style
    .marginLeft;
  if (getSlideWidthMgLeft) {
    getSlideWidthMgLeft = parseFloat(getSlideWidthMgLeft);
  } else {
    getSlideWidthMgLeft = 0;
  }

  let getSlideWidthMgRight = document.querySelector(".swiper-slide-active")
    .style.marginRight;
  if (getSlideWidthMgRight) {
    getSlideWidthMgRight = parseFloat(getSlideWidthMgRight);
  } else {
    getSlideWidthMgRight = 0;
  }

  let getSlideWidth = document.querySelector(
    ".swiper-slide-active"
  ).offsetWidth;

  let getTotalSlideWidth =
    getSlideWidthMgLeft + getSlideWidthMgRight + getSlideWidth;
  let diff = -getTotalSlideWidth - (getTranslate % getTotalSlideWidth);
  let diffTime = diff / -getSlideWidth;
  swiper2.setTranslate(getTranslate + diff);
  swiper2.setTransition(sliderSpeed * diffTime);
}
